<template>
    <o-data-lookup :data-object="dsRolesLkp" :whereClause="whereClause">
        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.role" :ref="scope.target" style="cursor: pointer;">
                <slot name="role"></slot>
            </span>
        </template>
        <o-column field="ID" width="80" sortable></o-column>
      <o-column field="Title" width="200" sortable></o-column>     
    </o-data-lookup>
</template>

<script setup>
import { computed, defineProps, watchEffect, watch } from 'vue';
import { getOrCreateDataObject } from 'o365.vue.ts';

const defaultView = "stbv_System_Roles";

const props = defineProps({
    viewName: {
        type: String,
        required: false,
        default: defaultView,
    },
    dataObject: {
        type: Object,
        required: false,
    },
    whereClause: {
        type: String,
        required: false,
    },
    noRecent: {
        type: Boolean,
        default: false
    }
});

const viewName = computed(() => props.viewName);
const dataObject = computed(() => props.dataObject);
const noRecent = computed(() => props.noRecent);

const dsRolesLkp = dataObject.value ? dataObject.value : (getOrCreateDataObject({
    id: `o_dsRolesLkp_${viewName.value}`,
    viewName: viewName.value, 
    maxRecords: 100,
    whereClause: props.whereClause,
    loadRecents: !noRecent.value,
    fields: [
        { name: "ID", type: "number" },
        { name: "Title", type: "string", sortOrder: 1, sortDirection: "asc"},
    ]
}));

// Re-set data object options when relevant props changes, but only if we do not have a data object set directly.
/*
watchEffect(() => {
    if (!dataObject.value) {
        dsRolesLkp.whereClause = whereClause.value || "";
        dsRolesLkp.loadRecents = noRecent.value;
        dsRolesLkp.viewName = viewName.value || defaultView;
    }    
});
*/

</script>

<style scoped>
</style>